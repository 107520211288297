const HOTELPROVIDERNAME = {
  ostrovok: 'Островок',
  'bronevik-online': 'Броневик онлайн',
  academ: 'Академсервис',
  expedia: 'Expedia',
  tripcom: 'Trip.com',
  direct: 'Direct',
  aanda: 'A&A',
  hotelbook: 'hotelbook',
  booking: 'Booking.com',
  CustomProvider: 'Кастомный провайдер',
  travelline: 'TravelLine',
  verdeho3d: 'Verdeho3D',
  verdeho: 'Verdeho',
  satguru: 'Satguru',
  alvist: 'Alvist',
  goGlobalTravel: 'Go Global Travel',
  rcb: 'РЦБ',
  verdehoAgent: 'verdeho agent',
};

const HOTEL_PROVIDER_VALUE = {
  ostrovok: 'ostrovok',
  'bronevik-online': 'bronevik-online',
  bronevik: 'bronevik',
  academ: 'academ',
  expedia: 'expedia',
  tripcom: 'tripcom',
  direct: 'direct',
  aanda: 'aanda',
  hotelbook: 'hotelbook',
  booking: 'booking',
  travelline: 'travelline',
  verdeho3d: 'verdeho3d',
  verdeho: 'verdeho',
  satguru: 'satguru',
  alvist: 'alvist',
  goGlobalTravel: 'goGlobalTravel',
  rcb: 'rcb',
  verdehoAgent: 'verdeho agent',
};

const PROVIDERS_AVAILABLE_INN = [
  'direct',
  'booking',
  'verdeho',
  'verdeho3d',
  'rcb',
  'verdeho agent',
];

const HOTEL_PROVIDER_IS_AUTO_CALC_VAT = [
  HOTEL_PROVIDER_VALUE.verdeho3d,
  HOTEL_PROVIDER_VALUE.rcb,
];

const ROOM_TYPE = {
  WITHOUT_CANCELLATION_AND_BREAKFAST: 1,
  BREAKFAST_WITHOUT_CANCELLATION: 2,
  CANCELLATION_WITHOUT_BREAKFAST: 3,
  WITH_CANCELLATION_AND_BREAKFAST: 4,
};

const FIELDS_VAT = {
  RATE: 'Rate',
  RACK_RATE: 'RackRate',
  AMOUNT: 'Amount',
};

const CURRENCY_VALUES = { USD: 'USD' };

const ALL_ROOM_INDEX = 'all';

const INITIAL_ROOM_VALUES = {
  id: 0,
  roomCategory: '',
  employees: [],
  customers: [],
  price: {
    dailyPrice: '',
    commission: '',
    earlyCheckin: '',
    lateCheckout: '',
  },
  amenities: {
    hasMeal: false,
    meal: {
      include: false,
      name: '',
      category: 0,
      price: '',
    },
    hasFreeCancellation: false,
    freeCancellationDate: '',
    cancellationPenalties: [
      {
        from: '',
        base: '',
        total: '',
        additional: false,
      },
    ],
  },
  earlyCheckin: false,
  lateCheckout: false,
  checkinDate: '',
  checkinTime: '',
  checkoutDate: '',
  checkoutTime: '',
  departmentId: 0,
  projectId: 0,
  userAnalytics: {},
};

const VAT_RATES_AS_STRINGS = {
  TWENTY: '20',
  SEVEN: '7',
  FIVE: '5',
};

const VAT_RATES_AS_NUMBERS = {
  TWENTY: 20,
  SEVEN: 7,
  FIVE: 5,
};

export {
  HOTELPROVIDERNAME as default,
  HOTELPROVIDERNAME,
  HOTEL_PROVIDER_VALUE,
  PROVIDERS_AVAILABLE_INN,
  ROOM_TYPE,
  HOTEL_PROVIDER_IS_AUTO_CALC_VAT,
  FIELDS_VAT,
  CURRENCY_VALUES,
  ALL_ROOM_INDEX,
  INITIAL_ROOM_VALUES,
  VAT_RATES_AS_STRINGS,
  VAT_RATES_AS_NUMBERS,
};
