const REQUIREDEXTENSIONS = ['xlsx'];

const UPDATETIMEOUTMS = 2000;

const TAGFIELDS = {
  TEXTTAG: 'При необходимости сначала добавьте тэг, потом загрузите файл',
  HEADER: 'Добавить тэг к загружаемым контактам:',
  TAG: 'tag',
  ENTERTAG: 'Введите тэг',
};

const UPLOADFORM = {
  DESCRIPTION: 'Чтобы начать загрузку, перетащите файл сюда\nили',
  INVALIDEXTENSION: 'Некорректное расширение файла. Доступные для загрузки:',
  CHOOSEFILE: 'Выберите файл',
};

const LABELS_TABELS = {
  ID: 'Идентификатор',
  DATE: 'Дата создания',
  DONE: 'Статус загрузки',
  PROGRESS: 'Прогресс',
  BUTTON_DOWNLOAD: 'СКАЧАТЬ',
  BUTTON_UPDATE: 'ОБНОВИТЬ ТАБЛИЦУ',
  STATUS_DONE: 'Готово',
  STATUS_NO_DONE: 'Идет загрузка',
};

const LABELS_UPLOAD_CONTACTS = { MODAL_QUESTIONS: (fileName) => `Вы точно хотите загрузить файл ${fileName}?` };

export {
  REQUIREDEXTENSIONS,
  UPDATETIMEOUTMS,
  TAGFIELDS,
  UPLOADFORM,
  LABELS_TABELS,
  LABELS_UPLOAD_CONTACTS,
};
